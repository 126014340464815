import { Component, OnInit, OnChanges, SimpleChanges, Input, signal } from '@angular/core';
import { OrderStatusColorValuesService } from '../../../services/order-status-color-values.service';
import { BadgeModule } from 'primeng/badge';
import { TooltipModule } from 'primeng/tooltip';
import { CommonModule } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { statusToColorPipe } from '../../../pipes/statusToColor.pipe';
import { NamingConventionFilterPipe } from '../../../pipes/namingConvention.pipe';
declare var $: any;
// const eventsMinDistance = 70;
@Component({
  standalone: true,
  selector: 'app-horizontal-custom-timeline',
  templateUrl: './horizontal-custom-timeline.component.html',
  styleUrls: ['./horizontal-custom-timeline.component.scss'],
  imports: [BadgeModule, TooltipModule, CommonModule, OverlayPanelModule, statusToColorPipe, NamingConventionFilterPipe]
})
export class HorizontalCustomTimelineComponent implements OnInit, OnChanges {
  @Input() timelineData: any = [];
  @Input() timelineOuterLeftData: any = [];
  @Input() timelineOuterRightData: any = [];
  @Input() timelineOuterContainerWidth: number;
  @Input() etaDetails
  @Input() pageName: string = '';
  extractedEtaDetails
  @Input() timelineID
  totalValue: number;
  showClickedContentValue: any;
  statusInitial = signal<string | null>(null);
  childStatus = signal<string | null>(null)
  constructor(public orderStatuColorService : OrderStatusColorValuesService) {
    this.totalValue = 0;
   }

  ngOnInit(): void {
   
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      const change = changes[propName];
      if (propName === 'timelineData') {
        if (change.currentValue !== change.previousValue) {
          this.timelineData.forEach((value) => { 
            if (value.valueBtwCircles) {
              this.totalValue = this.totalValue + value.valueBtwCircles;
            }
            if (this.pageName === 'delivered') {
              this.statusInitial.set(this.orderStatuColorService.getStatusInitials(this.orderStatuColorService.getStatus(this.timelineData)));
              this.childStatus.set(this.orderStatuColorService.getStatus(this.timelineData));
            }
          });

        }
      }
      if (propName === 'etaDetails') {
        if (change.currentValue !== change.previousValue) {
        this.showETADeatils()
        }
      }
    }

  
  }

  showETADeatils() {
    const {deliverySequence,etaDetails}=this.etaDetails?.[this.timelineID] || {}
    if(deliverySequence && etaDetails){
      const prevCircleVal = deliverySequence === 1 ? 'P' : deliverySequence - 1;
      this.extractedEtaDetails={[prevCircleVal]:etaDetails}
    }
  }


  
  overlayPanelVisible:boolean
  cirlceClicked(data){
    this.showClickedContentValue = data.valueInCircle;
  }

  

  getTotalDistance() : number{
    let totalDistance = 0;
    this.timelineData?.forEach((o)=>{
      if(o.valueBtwCircles){totalDistance += o.valueBtwCircles;}
    })
    return (totalDistance/1000);
  }

  setDistanceLineWidth(value) {
    // const totalDistance = Number(this.timelineOuterRightData[0].value.split(" ")[0]);
    const totalDistance = this.getTotalDistance();
    // console.log("totalDistance: ", totalDistance);
    // let dividerValue: number = 100;
    // if (this.totalValue && this.timelineOuterContainerWidth && (this.totalValue > this.timelineOuterContainerWidth)) {
    //   dividerValue = dividerValue*2.5;
    // }
    // let minWidthStr: string = '';
    // let minWidth: number;
    // minWidth = value/dividerValue;
    // if (minWidth > 300) {
    //   minWidthStr = 300 + 'px';
    // } else {
    //   minWidthStr = minWidth + 30 + 'px';
    // }

    const progressContainer = document.getElementById("timeline");
    const fraction = value/(1000*totalDistance);
    const numerator = fraction * progressContainer.offsetWidth - 30;
    const denominator = progressContainer.offsetWidth - ((this.timelineData.length-1)*30);
    const percentageCovered = Math.max((numerator/denominator) * 100, 0);
    return percentageCovered+"%";
  }

  getInitials(value: string) {
    let initials = value[0].toUpperCase();
    for (let i = 1; i < value.length; i++) {
      if (value[i] === value[i].toUpperCase()) {
        initials += value[i];
      }
    }
    return initials;
  }

  checkStatus(arrayOfObjects) {
    const preferredStatuses = ["forceDelivered", "timeBoundCompleted", "manuallyCompleted", "delivered", "cancelled"];
    let foundStatus = null;
    for (let obj of arrayOfObjects) {
      if (preferredStatuses.includes(obj?.status)) {
        return obj.status;
      } else if (foundStatus === null) {
        foundStatus = obj?.status;
      }
    };
    return foundStatus;
  }

}


export interface HorizontalCustomTimelineData {
  valueInCircle: any;
  valueBtwCircles: string;
  contents: Array<any>;
}